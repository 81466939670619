import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import Link from 'gatsby-plugin-transition-link'
import contactImage1 from '../../images/nav-contact-1.jpg'
import contactImage2 from '../../images/nav-contact-2.jpg'
import contactImage3 from '../../images/nav-contact-3.jpg'
import contactImage4 from '../../images/nav-contact-4.jpg'
import contactImage5 from '../../images/nav-contact-5.jpg'
import contactImage6 from '../../images/nav-contact-6.jpg'
import { window, document } from 'browser-monads'

class Header extends React.Component {
  state = { showMenu: false, showNav: true, scrollPos: 0, contactImageNumber: 1, atTop: true }

  toggleMenu = () => {
    this.setState({
      showMenu: !this.state.showMenu
    })
  }

  closeMenu = () => {
    this.setState({
      showMenu: false
    })
  }

  handleScroll = this.handleScroll.bind(this)

  handleScroll () {
    if (window.scrollY > 20) {
      this.setState({
        atTop: false
      })
    } else {
      this.setState({
        atTop: true
      })
    }
    const { scrollPos } = this.state
    this.setState({
      scrollPos: document.body.getBoundingClientRect().top,
      showNav: document.body.getBoundingClientRect().top > scrollPos || (window.innerHeight + window.scrollY) >= (document.body.offsetHeight - 20) || scrollPos > -50
    })
  }

  setContactImage = imageNumber => {
    this.setState({
      contactImageNumber: imageNumber
    })
  }

  componentDidMount () {
    window.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount () {
    window.removeEventListener('scroll', this.handleScroll)
  }

  render () {
    const menuState = this.state.showMenu ? ' is-nav-open' : ''
    const navState = this.state.showNav ? ' is-visible' : ' is-hidden'
    const RenderJobCount = ({ data }) =>
      data.prismic.allCareerss.edges.map(({ node }) => {
        return (
          <span key="job-count">{node.jobs.length}</span>
        )
      })
    return (
      <nav className={`c-navbar${ navState }${ menuState }${ this.props.class ? ' ' + this.props.class : '' }`} id="navbar">
        <div className="c-navbar__wrapper">
          <div className="container-fluid">
            <div className="row">
              <div className="c-navbar__container">
                <div className="c-navbar__brand">
                  <Link
                    to="/"
                    className="c-navbar__brand-link"
                    entry={{ delay: 0.5, length: 0 }}
                    exit={{ length: 0.5 }}
                  >
                    {/* eslint-disable */}
                    <svg fill="none" viewBox="0 0 60 60"><path d="M60 49.0908H0v10.9091h60V49.0908z" fill="#1A4860"/><path d="M60 36.8181H0v10.9091h49.0909C55.1158 47.7272 60 42.843 60 36.8181z" fill="#0D5D76"/><path d="M49.0909 24.5454H0c0 6.0249 4.8842 10.9091 10.9091 10.9091H60c0-6.0249-4.8842-10.9091-10.9091-10.9091z" fill="#448A99"/><path d="M60 12.2727H10.9091C4.8841 12.2727 0 17.1569 0 23.1818h60V12.2727z" fill="#81B9BD"/><path d="M60 0H0v10.9091h60V0z" fill="#8ECDC0"/></svg>
                    {/* eslint-enable */}
                    <div className="c-navbar__brand-text">Scoot Science</div>
                  </Link>
                </div>
                <div className="c-navbar__links" id="navbar-links">
                  <div className="c-navbar__links-wrapper">
                    <ul className="c-navbar__list">
                      <li className="c-navbar__item">
                        <Link
                          to="/"
                          className="c-navbar__item-link"
                          activeClassName="is-current"
                          onClick={this.toggleMenu}
                          onMouseEnter={e => this.setContactImage(1)}
                          entry={{ delay: 0.5, length: 0 }}
                          exit={{ delay: 0.2, length: 0.5 }}
                        >
                          Home
                        </Link>
                      </li>
                      <li className="c-navbar__item">
                        <Link
                          to="/product"
                          className="c-navbar__item-link"
                          activeClassName="is-current"
                          onClick={this.toggleMenu}
                          onMouseEnter={e => this.setContactImage(2)}
                          entry={{ delay: 0.5, length: 0 }}
                          exit={{ delay: 0.2, length: 0.5 }}
                        >
                          SeaState Dashboard
                        </Link>
                      </li>
                      <li className="c-navbar__item">
                        <Link
                          to="/riskmanagement"
                          className="c-navbar__item-link"
                          activeClassName="is-current"
                          onClick={this.toggleMenu}
                          onMouseEnter={e => this.setContactImage(3)}
                          entry={{ delay: 0.5, length: 0 }}
                          exit={{ delay: 0.2, length: 0.5 }}
                        >
                          Risk Management
                        </Link>
                      </li>
                      <li className="c-navbar__item">
                        <Link
                          to="/investment"
                          className="c-navbar__item-link"
                          activeClassName="is-current"
                          onClick={this.toggleMenu}
                          onMouseEnter={e => this.setContactImage(4)}
                          entry={{ delay: 0.5, length: 0 }}
                          exit={{ delay: 0.2, length: 0.5 }}
                        >
                          Investment
                        </Link>
                      </li>
                      <li className="c-navbar__item">
                        <Link
                          to="/advisory"
                          className="c-navbar__item-link"
                          activeClassName="is-current"
                          onClick={this.toggleMenu}
                          onMouseEnter={e => this.setContactImage(5)}
                          entry={{ delay: 0.5, length: 0 }}
                          exit={{ delay: 0.2, length: 0.5 }}
                        >
                          Advisory
                        </Link>
                      </li>
                      <li className="c-navbar__item">
                        <Link
                          to="/about-us"
                          className="c-navbar__item-link"
                          activeClassName="is-current"
                          onClick={this.toggleMenu}
                          onMouseEnter={e => this.setContactImage(6)}
                          entry={{ delay: 0.5, length: 0 }}
                          exit={{ delay: 0.2, length: 0.5 }}
                        >
                          About Us
                        </Link>
                      </li>
                      <li className="c-navbar__item">
                        <Link
                          to="/blog"
                          className="c-navbar__item-link"
                          activeClassName="is-current"
                          onClick={this.toggleMenu}
                          onMouseEnter={e => this.setContactImage(1)}
                          entry={{ delay: 0.5, length: 0 }}
                          exit={{ delay: 0.2, length: 0.5 }}
                        >
                          Blog
                        </Link>
                      </li>
                      <li className="c-navbar__item">
                        <a
                          href="https://wellfound.com/l/2znVYV"
                          className="c-navbar__item-link"
                          onClick={this.toggleMenu}
                          onMouseEnter={e => this.setContactImage(2)}
                        >
                          Careers
                        </a>
                      </li>
                      <li className="c-navbar__item">
                        <Link
                          to="/contact-us"
                          className="c-navbar__item-link"
                          activeClassName="is-current"
                          onClick={this.toggleMenu}
                          onMouseEnter={e => this.setContactImage(3)}
                          entry={{ delay: 0.5, length: 0 }}
                          exit={{ delay: 0.2, length: 0.5 }}
                        >
                          Contact Us
                        </Link>
                      </li>
                    </ul>
                    <div className="c-navbar__contact">
                      <div className={`c-navbar__contact-image-container c-navbar__contact-image-container--image${ this.state.contactImageNumber }`}>
                        <img src={contactImage1} className="c-navbar__contact-image" alt="" />
                        <img src={contactImage2} className="c-navbar__contact-image" alt="" />
                        <img src={contactImage3} className="c-navbar__contact-image" alt="" />
                        <img src={contactImage4} className="c-navbar__contact-image" alt="" />
                        <img src={contactImage5} className="c-navbar__contact-image" alt="" />
                        <img src={contactImage6} className="c-navbar__contact-image" alt="" />
                      </div>
                      <div className="c-navbar__contact-text">
                        Do you have any questions?
                        <br/>
                        <Link
                          to="/contact-us"
                          onClick={this.toggleMenu}
                          entry={{ delay: 0.5, length: 0 }}
                          exit={{ delay: 0.2, length: 0.5 }}
                        >
                          Get in touch
                        </Link>
                      </div>
                    </div>
                    <Link
                      to="/contact-us"
                      className="c-navbar__demo c-btn"
                      onClick={this.toggleMenu}
                      entry={{ delay: 0.5, length: 0 }}
                      exit={{ delay: 0.2, length: 0.5 }}
                    >
                      View demo
                    </Link>
                  </div>
                </div>
                <div className="c-navbar__overlay" onClick={this.toggleMenu} />
                <div className="c-navbar__menu-container">
                  <button className = {`c-navbar__menu ${ menuState }`} aria-controls="navbar-links" type="button" onClick={this.toggleMenu}>
                    <span className="u-visually-hide">Menu</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={`c-navbar__brand-text${ this.state.atTop ? '' : ' is-hidden' }`}>ScootScience</div>
        <div className="c-navbar__demo">
          <a
            href="https://scootscience.docsend.com/view/s/3wq5jqrfxydmke3u"
            className="c-btn"
            onClick={this.closeMenu}
            entry={{ delay: 0.5, length: 0 }}
            exit={{ delay: 0.2, length: 0.5 }}
          >
            View demo
          </a>
        </div>
      </nav>
    )
  }
}

const staticQuery = graphql`
  query {
    prismic {
      allCareerss {
        edges {
          node {
            jobs {
              jobtitle
            }
          }
        }
      }
    }
  }
`

export default Header
