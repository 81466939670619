import React from 'react'

class Loader extends React.Component {
  render () {
    return (
      <div className="c-loader">
        {/* eslint-disable */}
        <svg fill="none" viewBox="0 0 60 60"><path d="M60 49.0908H0v10.9091h60V49.0908z" fill="#ffffff"/><path d="M60 36.8181H0v10.9091h49.0909C55.1158 47.7272 60 42.843 60 36.8181z" fill="#ffffff"/><path d="M49.0909 24.5454H0c0 6.0249 4.8842 10.9091 10.9091 10.9091H60c0-6.0249-4.8842-10.9091-10.9091-10.9091z" fill="#ffffff"/><path d="M60 12.2727H10.9091C4.8841 12.2727 0 17.1569 0 23.1818h60V12.2727z" fill="#ffffff"/><path d="M60 0H0v10.9091h60V0z" fill="#ffffff"/></svg>
        {/* eslint-enable */}
      </div>
    )
  }
}

export default Loader
