import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import 'focus-visible'
import { TransitionState } from 'gatsby-plugin-transition-link'
import Header from '../header/header'
import Footer from '../footer/footer'
import '../../styles/site.scss'
import Loader from '../loader/loader'
import 'lazysizes'
import { window } from 'browser-monads'

const staticQuery = graphql`
    {
        site {
            siteMetadata {
                title
            }
        }
    }`

const Layout = ({ children, footerClass, headerClass }) => (
  <StaticQuery
    query={`${ staticQuery }`}
    render={data => (
      <>
        <a href="#main-content">Skip to content</a>
        <Loader />
        <Header class={headerClass} />
        <TransitionState>
          {({ transitionStatus, entry }) => {
            if (transitionStatus === 'entering') {
              window.scrollTo(0, 0)
            }
            if (entry.state.category !== undefined) {
              window.location.hash = entry.state.category
              entry.state.category = undefined
            }
            return (
              <>
                <main id="main-content">{children}</main>
                <Footer class={footerClass} />
              </>
            )
          }}
        </TransitionState>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
